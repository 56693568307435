import { toRefs } from 'vue';

export default function () {
  const attrs = reactive({
    height: 1024,
    width: 1024,
  });

  const mobile = computed(() => {
    return attrs.width < 768;
  });

  function update() {
    attrs.height = window.innerHeight;
    attrs.width = window.innerWidth;
  }

  onMounted(() => {
    update();
    window.addEventListener('resize', update);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', update);
  });

  return {
    ...toRefs(attrs),
    isMobile: mobile,
  };
}
